import type { ReactNode } from 'react';
import React, { createContext, useMemo, useContext } from 'react';
import { useIntl } from 'react-intl-next';

import { useSessionData } from '@confluence/session-data';

const FEEDBACK_CONTEXT_CF = 'customfield_10047';

export interface FeedbackCollectorProperties {
	entrypointId: string;
	locale: string;
	userId?: string;
	displayName?: string;
	feedbackContextId: string;
	feedbackContext: string;
}

type FeedbackCollectorPropertiesContextType = FeedbackCollectorProperties | undefined;

export const FeedbackCollectorPropertiesContext =
	createContext<FeedbackCollectorPropertiesContextType>(undefined);

type FeedbackCollectorPropertiesProviderProps = {
	children: ReactNode;
	entrypointId: string;
	locale?: string;
	userId?: string;
	displayName?: string;
	feedbackContextId?: string;
	feedbackContext?: object;
};

export const FeedbackCollectorPropertiesProvider: React.FC<
	FeedbackCollectorPropertiesProviderProps
> = ({
	children,
	entrypointId,
	locale,
	feedbackContextId = FEEDBACK_CONTEXT_CF,
	feedbackContext = {},
	userId,
	displayName,
}) => {
	const { displayName: sessionDisplayName, userId: sessionUserId } = useSessionData();
	const { locale: intlLocale } = useIntl();

	const feedbackContextString = Object.entries(feedbackContext)
		.map(([key, value]) => `${key}: ${JSON.stringify(value)}`)
		.join(' ');

	const contextValue = useMemo<FeedbackCollectorProperties>(
		() => ({
			entrypointId,
			feedbackContextId,
			feedbackContext: feedbackContextString,
			locale: locale || intlLocale,
			userId: userId || sessionUserId || undefined,
			displayName: displayName || sessionDisplayName || undefined,
		}),
		[
			entrypointId,
			feedbackContextId,
			feedbackContextString,
			locale,
			intlLocale,
			userId,
			sessionUserId,
			displayName,
			sessionDisplayName,
		],
	);

	return (
		<FeedbackCollectorPropertiesContext.Provider value={contextValue}>
			{children}
		</FeedbackCollectorPropertiesContext.Provider>
	);
};

export const useFeedbackCollectorProperties = (): FeedbackCollectorProperties => {
	const context = useContext(FeedbackCollectorPropertiesContext);

	if (context === undefined) {
		throw new Error(
			'useFeedbackCollectorProperties must be used within a FeedbackCollectorPropertiesProvider',
		);
	}

	return context;
};
