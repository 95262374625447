import { useQuery } from 'react-apollo';
import type { ApolloError } from 'apollo-client';

import { ContentContributorsQuery } from '../ContentContributorQuery.graphql';
import type {
	ContentContributorsQuery as ContentContributorsQueryType,
	ContentContributorsQueryVariables,
} from '../__types__/ContentContributorsQuery';

type CurrentUserContributorProperties = {
	isCurrentUserContributor?: boolean;
	contentContributorsLoading: boolean;
	contentContributorsError?: ApolloError;
};

export const useCurrentUserContributor = (contentId: string): CurrentUserContributorProperties => {
	const {
		data,
		loading: contentContributorsLoading,
		error: contentContributorsError,
	} = useQuery<ContentContributorsQueryType, ContentContributorsQueryVariables>(
		ContentContributorsQuery,
		{
			variables: { contentId },
		},
	);

	const isCurrentUserContributor: boolean | undefined =
		data?.contentContributors?.isCurrentUserContributor ?? undefined;

	return {
		isCurrentUserContributor,
		contentContributorsLoading,
		contentContributorsError,
	};
};
