export type AIEventBasePayload = {
	product: string;
	subproduct: string;
	/**
	 * If the same AI experience fires in different location in the product, then location where it fired.
	 */
	source?: string;
	actionSubjectId?: string;
	tags?: string[];
};

export type AIEventBaseAttributes = {
	/**
	 * The name of the feature
	 *
	 * Our recommendation for consistent feature names:
	 *  - Use camel case.
	 *  - Use a meaningful name that can distinguish this feature from others.
	 *  	- Recommended: smartSummarizeComment
	 *  	- Not Recommended: Summary
	 *  - Drop product name from the feature name.
	 *  	- Recommended: smartSummarizeComment.
	 *  	- Not Recommended: jsmSmartSummarizeComment.
	 *  - Drop platform from the feature name
	 *  	- Recommended: abbreviationsDefinitions
	 *  	- Not Recommended: mobileAbbreviationsDefinitions
	 *
	 * In doubt about the best name? please reach out to :slack: ai-features-instrumentation tagging !help-data-team
	 */
	aiFeatureName: string;
	/**
	 * An attribute that captures the fact that an AI generated result was proactively served to a user.
	 * Note: This attribute was previously called proactiveAIGenerated. Please use the new name for new features. For older features, we recommend using the new name, but you have the option to keep it as is.
	 */
	proactiveGeneratedAI: 0 | 1;
	/**
	 * Attribute that captures the fact that the feature was not a proactive feature and a user had to interact to get an AI result.
	 */
	userGeneratedAI: 0 | 1;
	/**
	 * Attribute that captures that the event is originating from an ai feature. This helps us isolate events coming from AI features from all other events.
	 */
	isAIFeature?: 0 | 1;
	/**
	 * Attribute that captures the individual unique message sent to ai gateway. the messageid should be unique across aiResult viewed and aiResult error events.
	 * messageId is provided for features that requires textual user input (like Rovo chat and natural language to SQL).
	 */
	messageId?: string;
	/**
	 * Some AI features have multiple experiences that the user can choose from. For example, the editor AI feature in Confluence. This attribute captures the name of the experience that the user choses to interact with.
	 */
	aiExperienceName?: string;
	/**
	 * A unique ID that persists through out the AI journey and allows us to track the various parts of the unified events. This attribute is optional and should be used when the ai interaction spans over user sessions, otherwise na defaul UUID will be generated per session.
	 */
	singleInstrumentationID?: string;
};

export type AIEventErrorAttributes = {
	/**
	 * Please add a note to make sure that NO UGC data is getting logged. Just a high level descriptive error message is enough for us.
	 */
	aiErrorMessage: string;
	aiErrorCode?: number;
};

export type AIFeedbackResultType = 'up' | 'down';

export type AdditionalAttributes = Record<string, any>;

export interface InternalAIEventPayload extends Omit<AIEventBasePayload, 'product'> {
	/* we need to override this only due to the @atlaskit/analytics-next
	 already has product define, and we don't want to override this as for right now */
	product?: string;
	actionSubject: string;
	action: string;
	tags?: string[];
	attributes: Partial<AIEventBaseAttributes> &
		Partial<AIEventErrorAttributes> &
		AdditionalAttributes & {
			/**
			 * This attribute should capture the value when the user choses to provide  :thumbsup: :thumbsdown:   feedback. The value can be up or down.
			 * Please note that a user could also provide a message in their feedback and hit the Send Feedback button. This event doesn’t apply to this attribute. We are only interested in the up or down feedback.
			 */
			aiFeedbackResult?: AIFeedbackResultType;
			/**
			 * This attribute captures the action that the user took in order to be qualified for the aiResult actioned(client) event. This could be any of the following (please consider adding to this list if none of them can properly describe the action):
			 * - select, copy
			 * - copyButtonClicked
			 * -  codeblockCopied
			 * - citationClicked
			 * - sourceLinkClicked
			 * - recommendationAccepted
			 * - ruleSaved
			 */
			aiResultAction?: string;
		};
}

export type InternalAIEvent =
	| InternalDefaultAIEvent
	| InternalConfluenceAIEvent
	| InternalJiraAIEvent;

export interface InternalAIBaseEventPaylod extends Omit<InternalAIEventPayload, 'subproduct'> {
	// some product analytics can override this property such as Jira so we don't need to provide it
	subproduct?: string;
}

interface InternalDefaultAIEvent extends InternalAIEventPayload {
	eventType: 'track';
}

export type InternalJiraAIEvent = Omit<
	InternalAIEventPayload,
	'source' | 'tags' | 'product' | 'subproduct'
>;

interface InternalConfluenceAIEvent {
	type: 'sendTrackEvent';
	data: InternalAIEventPayload;
}

export type AIEventPayload = Omit<
	InternalAIEventPayload,
	'product' | 'subproduct' | 'actionSubject' | 'action'
>;

export type AIResultViewedPayload = AIEventPayload & {
	/**
	 * This attribute allows for custom success tracking for non-standard events.
	 * The default value will be false marking standard view events as success.
	 *
	 * To handle a non-standard success case (such as only counting proactive success after Xs dwell)
	 * Simply send a first trackAIResultViewed({doesNotMeetMAUCriteria: true, ...}) then followup
	 * with a second default trackAIResulViewed(). The second event will be counted towards AI success
	 *
	 * This value will be set on the attributes part of the payload on event send.
	 */
	doesNotMeetMAUCriteria?: boolean;
};

export type OverrideAIEventPayload = Pick<InternalAIEventPayload, 'action' | 'actionSubject'> & {
	attributes?: Pick<
		InternalAIEventPayload['attributes'],
		'aiFeedbackResult' | 'aiResultAction' | 'aiErrorMessage' | 'aiErrorCode'
	>;
};

export enum AISessionState {
	'unset' = '[AIInteraction]:UNSET',
	'initiated' = '[AIInteraction]:INITIATED',
	'viewed' = '[AIInteraction]:VIEWED',
	'error' = '[AIInteraction]:ERROR',
	'dismissed' = '[AIInteraction]:DISMISSED',
	'actioned' = '[AIInteraction]:ACTIONED',
	'submitted' = '[AIInteraction]:SUBMITTED',
}

export enum EventDispatcherSources {
	'JSLib' = 'name:@atlassian/ai-analytics|language:javascript',
}
