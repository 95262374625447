import { useCallback, useContext } from 'react';

import { match, P } from 'ts-pattern';

import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import { AIEventsInstrumentationContext } from '../context';
import {
	type AIEventErrorAttributes,
	type AIEventPayload,
	type AIFeedbackResultType,
	type AIResultViewedPayload,
	AISessionState,
	type InternalAIBaseEventPaylod,
	type InternalAIEvent,
	type OverrideAIEventPayload,
} from '../events';

export const useAIEventsInstrumentation = () => {
	const context = useContext(AIEventsInstrumentationContext);

	const { createAnalyticsEvent } = useAnalyticsEvents();

	if (context === null) {
		throw new Error(
			'useAIEventsInstrumentation must be used within a AIEventsInstrumentationProvider',
		);
	}

	const {
		config: {
			source,
			aiExperienceName,
			aiFeatureName,
			proactiveGeneratedAI,
			userGeneratedAI,
			isAIFeature,
			channel,
			subproduct,
			actionSubjectId,
			tags,
			product,
			eventDispatcherSource,
			skipAISessionValidation,
			customAnalyticsFire,
			additionalAttributes,
			useDefaultGASv3EventSchema,
		},
		refreshSingleInstrumentationID,
		getSingleInstrumentationID,
		setAISessionState,
		getAISessionState,
	} = context;

	const validateAndUpdateAISession = useCallback(
		(nextState: AISessionState): boolean => {
			const currentAISessionState = getAISessionState();

			return match({ currentState: currentAISessionState, nextState, skipAISessionValidation })
				.with({ skipAISessionValidation: true }, () => true)
				.with(
					{
						currentState: AISessionState.unset,
						nextState: P.union(
							AISessionState.viewed,
							AISessionState.error,
							AISessionState.dismissed,
						),
					},
					() => {
						// eslint-disable-next-line no-console
						console.error(
							`[@atlassian/ai-analytics]: ${nextState} event should be called only after trackAIInteractionInit()`,
						);
						return false;
					},
				)
				.with(
					{
						currentState: AISessionState.initiated,
						nextState: P.union(AISessionState.submitted, AISessionState.actioned),
					},
					() => {
						// eslint-disable-next-line no-console
						console.error(
							`[@atlassian/ai-analytics]: ${nextState} event should be called only after trackAIResultView() OR trackAIResultError() OR trackAIInteractionDismiss() event`,
						);
						return false;
					},
				)
				.otherwise(() => {
					setAISessionState(nextState);
					return true;
				});
		},
		[setAISessionState, getAISessionState, skipAISessionValidation],
	);

	const fireEventWithOptionalChannel = useCallback(
		(event: InternalAIEvent) => {
			if (typeof customAnalyticsFire === 'function') {
				customAnalyticsFire(event, createAnalyticsEvent({}));
				return;
			}
			if (channel) {
				createAnalyticsEvent(event).fire(channel);
			} else {
				createAnalyticsEvent(event).fire();
			}
		},
		[channel, customAnalyticsFire, createAnalyticsEvent],
	);

	const createAndMergeEventPayload = useCallback(
		(
			payload: OverrideAIEventPayload,
			partialPayload?: Partial<AIEventPayload>,
		): InternalAIEvent => {
			const singleInstrumentationID = getSingleInstrumentationID();

			const eventPayload: InternalAIBaseEventPaylod = {
				action: payload.action,
				actionSubject: payload.actionSubject,
				actionSubjectId: partialPayload?.actionSubjectId ?? actionSubjectId,
				attributes: {
					aiExperienceName,
					aiFeatureName,
					proactiveGeneratedAI,
					userGeneratedAI,
					isAIFeature,
					eventDispatcherSource,
					singleInstrumentationID,
					...payload.attributes,
					...partialPayload?.attributes,
					...additionalAttributes,
				},
			};

			return match<
				{
					product: string;
					channel: string | undefined;
					useDefaultGASv3EventSchema: boolean | undefined;
				},
				InternalAIEvent
			>({
				product,
				channel,
				useDefaultGASv3EventSchema,
			})
				.with(
					{ product: 'confluence', channel: P.nullish, useDefaultGASv3EventSchema: false },
					() => {
						// confluence event validation fronted logic
						// https://stash.atlassian.com/projects/ATLASSIAN/repos/atlassian-frontend-monorepo/browse/jira/src/packages/platform/observability/product-analytics-bridge/src/utils/fire-analytics.tsx#83
						// it requires to use `data` inside event shape and only following event types
						// https://stash.atlassian.com/projects/ATLASSIAN/repos/atlassian-frontend-monorepo/browse/confluence/next/packages/analytics/src/analyticsListener.tsx#11
						return {
							type: 'sendTrackEvent',
							data: {
								...eventPayload,
								tags: partialPayload?.tags ?? tags,
								source: partialPayload?.source ?? source,
								subproduct,
							},
						};
					},
				)
				.with({ product: 'jira', channel: P.nullish, useDefaultGASv3EventSchema: false }, () => {
					// jira event validation fronted logic
					// https://stash.atlassian.com/projects/ATLASSIAN/repos/atlassian-frontend-monorepo/browse/jira/src/packages/platform/observability/product-analytics-bridge/src/utils/fire-analytics.tsx#83
					// it requires to use no more than 4 properties on the top level of event shape
					return eventPayload;
				})
				.otherwise(() => {
					// for any other product type we should use the default event shape
					// also if there is a custom event channel, we also should use the default event shape
					return {
						eventType: 'track',
						...eventPayload,
						tags: partialPayload?.tags ?? tags,
						source: partialPayload?.source ?? source,
						subproduct,
						product,
					};
				});
		},
		[
			subproduct,
			channel,
			aiExperienceName,
			aiFeatureName,
			product,
			proactiveGeneratedAI,
			getSingleInstrumentationID,
			useDefaultGASv3EventSchema,
			userGeneratedAI,
			isAIFeature,
			source,
			tags,
			eventDispatcherSource,
			actionSubjectId,
			additionalAttributes,
		],
	);

	const trackAIInteractionInit = useCallback(
		(payload?: Partial<AIEventPayload>) => {
			if (!validateAndUpdateAISession(AISessionState.initiated)) {
				return;
			}

			refreshSingleInstrumentationID(payload?.attributes?.singleInstrumentationID);
			const event = createAndMergeEventPayload(
				{
					action: 'initiated',
					actionSubject: 'aiInteraction',
				},
				payload,
			);

			fireEventWithOptionalChannel(event);
		},
		[
			refreshSingleInstrumentationID,
			createAndMergeEventPayload,
			fireEventWithOptionalChannel,
			validateAndUpdateAISession,
		],
	);

	const trackAIResultView = useCallback(
		(payload?: Partial<AIResultViewedPayload>) => {
			if (!validateAndUpdateAISession(AISessionState.viewed)) {
				return;
			}
			const { doesNotMeetMAUCriteria, attributes, ...payloadRest } = payload || {};

			const event = createAndMergeEventPayload(
				{
					action: 'viewed',
					actionSubject: 'aiResult',
				},
				{
					...payloadRest,
					attributes: {
						...attributes,
						doesNotMeetMAUCriteria: !!doesNotMeetMAUCriteria,
					},
				},
			);

			fireEventWithOptionalChannel(event);
		},
		[validateAndUpdateAISession, createAndMergeEventPayload, fireEventWithOptionalChannel],
	);

	const trackAIResultError = useCallback(
		(error: AIEventErrorAttributes, payload?: Partial<AIEventPayload>) => {
			if (!validateAndUpdateAISession(AISessionState.error)) {
				return;
			}
			const event = createAndMergeEventPayload(
				{
					action: 'error',
					actionSubject: 'aiResult',
					attributes: {
						...error,
					},
				},
				payload,
			);

			fireEventWithOptionalChannel(event);
		},
		[validateAndUpdateAISession, createAndMergeEventPayload, fireEventWithOptionalChannel],
	);

	const trackAIInteractionDismiss = useCallback(
		(payload?: Partial<AIEventPayload>) => {
			if (!validateAndUpdateAISession(AISessionState.dismissed)) {
				return;
			}
			const event = createAndMergeEventPayload(
				{
					action: 'dismissed',
					actionSubject: 'aiInteraction',
				},
				payload,
			);

			fireEventWithOptionalChannel(event);
		},
		[validateAndUpdateAISession, createAndMergeEventPayload, fireEventWithOptionalChannel],
	);

	const trackAIResultAction = useCallback(
		(aiResultAction: string, payload?: Partial<AIEventPayload>) => {
			if (!validateAndUpdateAISession(AISessionState.actioned)) {
				return;
			}
			const event = createAndMergeEventPayload(
				{
					action: 'actioned',
					actionSubject: 'aiResult',
					attributes: {
						aiResultAction,
					},
				},
				payload,
			);

			fireEventWithOptionalChannel(event);
		},
		[validateAndUpdateAISession, createAndMergeEventPayload, fireEventWithOptionalChannel],
	);

	const trackAIFeedbackSubmit = useCallback(
		(aiFeedbackResult: AIFeedbackResultType, payload?: Partial<AIEventPayload>) => {
			if (!validateAndUpdateAISession(AISessionState.submitted)) {
				return;
			}
			const event = createAndMergeEventPayload(
				{
					action: 'submitted',
					actionSubject: 'aiFeedback',
					attributes: {
						aiFeedbackResult,
					},
				},
				payload,
			);

			fireEventWithOptionalChannel(event);
		},
		[validateAndUpdateAISession, createAndMergeEventPayload, fireEventWithOptionalChannel],
	);

	return {
		trackAIInteractionInit,
		trackAIResultView,
		trackAIResultError,
		trackAIInteractionDismiss,
		trackAIResultAction,
		trackAIFeedbackSubmit,
		refreshSingleInstrumentationID,
	};
};
