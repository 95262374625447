import { createContext } from 'react';

import type UIAnalyticsEvent from '@atlaskit/analytics-next/UIAnalyticsEvent';

import type {
	AdditionalAttributes,
	AIEventBaseAttributes,
	AIEventBasePayload,
	AISessionState,
	EventDispatcherSources,
	InternalAIEvent,
} from '../events';

export const AIEventsInstrumentationContext =
	createContext<AIEventsInstrumentationContextType>(null);

type AIAnalyticsConfig = Partial<{
	channel: string;
	/**
	 * for internal usage to be able to track non lib/lib events
	 */
	eventDispatcherSource: EventDispatcherSources;
	/**
	 * allows to skip ai session validation on frontend side.
	 */
	skipAISessionValidation: boolean;
	/**
	 * allows to use the default event shape of event schema (non-specific for confluence or jira)
	 */
	useDefaultGASv3EventSchema: boolean;
	/**
	 * provides a callback to fire event with custom fire logic
	 */
	customAnalyticsFire: (aiEvent: InternalAIEvent, event: UIAnalyticsEvent) => void;
	/**
	 * Additional key/value paired attributes to be passed through with every unified event
	 */
	additionalAttributes: AdditionalAttributes;
}>;

export type AIEventsInstrumentationConfig = AIEventBasePayload &
	AIEventBaseAttributes &
	AIAnalyticsConfig;

export type AIEventsInstrumentationContextType = {
	config: AIEventsInstrumentationConfig;
	refreshSingleInstrumentationID: (customSingleInstrumentationID?: string) => void;
	getSingleInstrumentationID: () => string | undefined;
	setAISessionState: (nextState: AISessionState) => void;
	getAISessionState: () => AISessionState;
} | null;
